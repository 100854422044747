import React from "react"
import { graphql } from "gatsby"
import { styled } from "twin.macro"
//
import Layout from "../components/layout/Layout"
import { getPreviewPage } from "../utils/preview"
import { Container } from "../storybook/components/misc/Layouts"
import { renderComponent } from "../utils/components-preview"

const PreviewLoader = styled.div`
  text-align: center;
  margin-top: 20%;
  font-weight: bold;
  font-size: 150%;
`

const PreviewContainer = styled.div`
  position: fixed;
  left: 10px;
  top: 10px;
  padding: 4px 8px;
  background-color: #000;
  color: #fff;
  z-index: 9999;
  text-align: center;
  border: 1px solid #fff;
`

const Preview = ({ location, data }) => {
  const urlParams = new URLSearchParams(location?.search)
  const slug = urlParams?.get("slug") || "homepage"

  const variables = { slug, locale: "pt" }

  const [hero, setHero] = React.useState(null)
  const [body, setBody] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    getPreviewPage(variables).then((page) => {
      const { hero, componentesCollection } = page
      setHero(hero)
      setBody(componentesCollection?.items)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  if (loading) {
    return <PreviewLoader>Loading preview...</PreviewLoader>
  }

  return (
    <Layout key={`preview-${slug}`} tags={{ title: "Preview for..." }}>
      <PreviewContainer>Preview Mode</PreviewContainer>
      {renderComponent(hero)}
      <Container id="main-section">{body.map((comp) => renderComponent(comp))}</Container>
    </Layout>
  )
}

export default Preview

export const previewQuery = graphql`
  query ($site: String!, $locale: String!) {
    contentfulSite(nome: { eq: $site }, node_locale: { eq: $locale }) {
      ...FragmentContentfulSite
    }

    site {
      ...FragmentSite
    }
  }
`
