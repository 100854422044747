/* eslint-disable react/display-name */
import React from "react"
import * as dot from "dot-wild"
//
import { createImageFluid } from "./image"

import HeroCarousel from "../components/blocks/hero-carousel/hero-carousel"
import BlocksTextAndImage from "../components/blocks/blocks-text-image/blocks-text-image"
import LatestNews from "../components/blocks/latest-news/latest-news"
import TextBanner from "../components/blocks/text-banner/text-banner"
import Newsletter from "../components/blocks/newsletter/newsletter"
import InfoGrid from "../components/blocks/info-grid/info-grid"
import HeroVideoImage from "../components/blocks/hero-video-image/hero-video-image"
import InfoList from "../components/blocks/info-list/info-list"
import BlockContacts from "../components/blocks/contacts/contacts-block"
import ContactsForm from "../components/blocks/contacts/contacts-form"
import BlockText from "../components/blocks/text/text"
import HeroSimple from "../components/blocks/hero-simple/hero-simple"

const compMappings = {
  HeroImagemFundo: (props) => <HeroCarousel data={props} />,
  BlocosTextoEImagemLateral: (props) => <BlocksTextAndImage data={props} />,
  BlocosUltimasNoticias: (props) => <LatestNews data={props} />,
  BlocosBannerTexto: (props) => <TextBanner data={props} />,
  BlocosNewsletter: (props) => <Newsletter data={props} />,
  BlocosInfoGrid: (props) => <InfoGrid data={props} />,
  HeroVideoOuImagem: (props) => <HeroVideoImage data={props} />,
  BlocosInfoList: (props) => <InfoList data={props} />,
  BlocosContactos: (props) => <BlockContacts data={props} />,
  BlocosFormularioDeContacto: (props) => <ContactsForm data={props} />,
  BlocosTextoFormatado: (props) => <BlockText data={props} />,
  HeroTituloSimples: (props) => <HeroSimple data={props} />
}

// const traverse = function (o, fn, scope = []) {
//   for (let i in o) {
//     fn.apply(this, [i, o[i], scope])
//     if (o[i] !== null && typeof o[i] === "object") {
//       traverse(o[i], fn, scope.concat(i))
//     }
//   }
// }

export const renderComponent = (comp, idx = 0) => {
  if (!Object.hasOwnProperty.call(compMappings, comp.__typename)) {
    console.error(`Component ${comp.__typename} not found!`)
    return null
  }

  const flatten = dot.flatten(comp)

  let newComp = {}

  Object.keys(flatten).forEach((key) => {
    let newKey = key
    if (/.*Collection\.items\..*/.test(newKey)) {
      newKey = newKey.replace(/Collection\.items/, "")
    }
    if (/sys\.id$/.test(newKey)) {
      newKey = newKey.replace("sys.id", "id")
    }
    if (/.*\.url$/.test(newKey) && /.*images\.ctfassets\.net.*/.test(flatten[key])) {
      newKey = newKey.replace(/\.url$/, "")
      newComp[newKey] = createImageFluid({ url: flatten[key], width: 960, height: 640 })
    } else if (/.*\.json\..*/.test(newKey)) {
      newKey = newKey.replace(/\.json\..*/, ".raw")
      if (!newComp[newKey]) {
        newComp[newKey] = JSON.stringify(dot.get(comp, key.replace(/\.json\..*/, ".json")))
      }
    } else {
      newComp[newKey] = flatten[key]
    }
  })

  const expandedComp = dot.expand(newComp)

  const MappedComponent = compMappings[comp.__typename]

  return <MappedComponent key={`${comp.sys.id}-${idx}`} {...expandedComp} />
}
